(function (FroalaEditor, $) {
  FroalaEditor.DEFAULTS = $.extend(FroalaEditor.DEFAULTS, {
    variableValue: [],
    isFreePlan: false,
    upgradeModal: ''
  });

  function getDropdownHTML(formFields, type) {
    var dropDownHtml = '<ul class="fr-dropdown-list fr-dropdown-menu-list variable-dropdown" role="presentation">';
    if (formFields.length > 0) {
      for (var step in formFields) {
        if (formFields[step].type == 'Step') {
          dropDownHtml += '<span>' + formFields[step].name + '</span>';
          for (var capture in formFields[step].capture) {
            dropDownHtml += '<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="insertVariableDropdown" data-param1="' + formFields[step].capture[capture].alias + '" title="' + (formFields[step].capture[capture].full_label || formFields[step].capture[capture].label) + '" aria-selected="false">' + formFields[step].capture[capture].label + '' + formFields[step].capture[capture].type_field + '</a></li>';
          }
        }
        else if (formFields[step].type == 'Prerun') {
          dropDownHtml += '<span>' + formFields[step].name + '</span>';
          for (var capture in formFields[step].capture) {
            dropDownHtml += '<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="insertVariableDropdown" data-param1="' + formFields[step].capture[capture].alias + '" title="' + (formFields[step].capture[capture].full_label || formFields[step].capture[capture].label) + '" aria-selected="false">' + formFields[step].capture[capture].label + '' + formFields[step].capture[capture].type_field + '</a></li>';
          }
        } else if (formFields[step].type === 'System') {
          dropDownHtml += '<span>' + formFields[step].name + '</span>';
          for (var capture in formFields[step].capture) {
            dropDownHtml += '<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="insertVariableDropdown" data-param1="' + formFields[step].capture[capture].alias + '" title="' + (formFields[step].capture[capture].full_label || formFields[step].capture[capture].label) + '" aria-selected="false">' + formFields[step].capture[capture].label + '' + formFields[step].capture[capture].type_field + '</a></li>';
          }
        } else {
          dropDownHtml += '<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="insertVariableDropdown" data-param1="' + formFields[step].id + '" title="' + formFields[step].full_label + '" aria-selected="false">' + formFields[step].label + '</a></li>';
        }
      }
      dropDownHtml += '</ul>';
    } else {
      dropDownHtml += '<span>Variables are placeholders for any form field value. There are no form fields in this ' + (type || 'template') + '. Please create a form field on any step to use it here. <a class="fr-command" target="_blank" href="https://tallyfy.com/products/pro/documenting/templates/variables/how-can-i-use-insert-variables-in-tallyfy/">More</a></span>';
      dropDownHtml += '</ul>';
    }
    return dropDownHtml;
  }

  // Define the plugin.
  FroalaEditor.PLUGINS.insertVariable = function (editor) {

    // The start point for your plugin.
    function _init() {
      editor.events.on('keyup', function (e) {
        var ranges = editor.selection.ranges()[0];
        if (!ranges) return;
        var element = $(ranges.startContainer).closest('.insert-variable-tag').get(0) || $(ranges.endContainer).closest('.insert-variable-tag').get(0);
        if (element) {
          var parent = element.parentNode;
          if (e.keyCode == 8 || e.keyCode == 46) {
            e.preventDefault();
            parent.removeChild(element);
          }
        }
      }, true);
    }

    // Public method that is visible in the instance scope.
    function insertAlias(val) {
      editor.html.insert('\uFEFF<span class="insert-variable-tag fr-deletable" contenteditable="false">{{' + val + '}}</span>\uFEFF');
    }

    function openUpgradeModal() {
      editor.opts.upgradeModal();
    }

    function updateVariableValues(editor, formFields) {
      var el = editor.$tb[0];
      var parent = $(el).find('.variable-dropdown').parent();
      parent.html('');
      var dropDownHtml = getDropdownHTML(formFields);
      parent.html(dropDownHtml);
    }

    return {
      _init: _init,
      insertAlias: insertAlias,
      openUpgradeModal: openUpgradeModal,
      updateVariableValues: updateVariableValues
    };
  };

  FroalaEditor.DefineIconTemplate('insert_variable_template', '<text class="vertical-align dropdown-text"><p class="_600 inline mb-0"><i class="fas fa-brackets-curly"></i></p></text>');
  FroalaEditor.DefineIcon('insertVariableDropdown', { template: 'insert_variable_template' });
  FroalaEditor.RegisterCommand('insertVariableDropdown', {
    title: 'Insert Variables',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: false,
    html: function () {
      var formFields = this.opts.variableValue;
      var dropDownHtml = getDropdownHTML(formFields, this.opts.entityType);
      return this.opts.isFreePlan ? '' : dropDownHtml;
    },
    callback: function (cmd, val) {
      this.insertVariable.insertAlias(val);
    },
    refresh: function () {
    },
    refreshOnShow: function () {
      this.insertVariable.openUpgradeModal();
    },
    plugin: 'insertVariable'
  });
})(FroalaEditor, jQuery);